import { FormRenderer } from "@data-driven-forms/react-form-renderer";
import componentTypes from "@data-driven-forms/react-form-renderer/component-types";
import { Alert } from "components/Alert";
import {
  FormTemplate,
  SeamlessFormTemplate,
  componentMapper
} from "components/form/FormTemplate";
import { FormError } from "components/FormError";
import { Button } from "components/ui/button";
import { helpScoutBeaconId, isDemoSite } from "consts/client";
import useAuth from "hooks/useAuth";
import useClient from "hooks/useClient";
import Link from "next/link";
import { useRouter } from "next/router";
import type { WindowWithBeacon } from "types";
import type { AuthData } from "types/auth";
import { FormTemplateType } from "types/form";

export const ExistingUserForm = ({
  redirect,
  submitLabel = "Log in",
  formTemplate
}: {
  redirect: string;
  submitLabel?: string;
  formTemplate?: FormTemplateType;
}) => {
  const router = useRouter();
  const clientQueryInfo = useClient();

  const [authenticateUser, authenticateUserInfo] = useAuth();

  const formSchema = {
    fields: [
      {
        index: 0,
        arrayField: false,
        component: componentTypes.TEXT_FIELD,
        name: "email",
        label: "Email address",
        isRequired: true,
        inputType: "email",
        validate: [{ type: "required" }],
        formTemplate
      },
      {
        index: 1,
        arrayField: false,
        component: componentTypes.TEXT_FIELD,
        name: "password",
        label: "Password",
        isRequired: true,
        inputType: "password",
        validate: [{ type: "required" }],
        formTemplate
      }
    ]
  };

  async function onSubmit(formData: AuthData) {
    const response = await authenticateUser(formData);

    if (response?.data.accessLevel >= 10 && typeof window !== "undefined") {
      const beaconWindow = (window as WindowWithBeacon)?.["Beacon"];

      if (beaconWindow && !beaconWindow("info")) {
        beaconWindow("init", helpScoutBeaconId);

        if (!isDemoSite) {
          beaconWindow("identify", {
            name: response?.data.fullName,
            email: response?.data.email,
            signature: response?.data.signature
          });
        }
      }
    }

    if (response?.status === 200) {
      router.push(redirect);
    }
  }

  if (
    authenticateUserInfo.error?.response?.data?.message?.includes(
      "set a password"
    )
  ) {
    return (
      <div className="flex flex-col gap-4">
        <Alert variant="yellow">
          You need to set a password before logging in. Please check your email
          for a link to set your password.
        </Alert>
        <Button
          onClick={() => authenticateUserInfo.reset()}
          size="sm"
          variant="outline"
        >
          Back to Login
        </Button>
      </div>
    );
  }

  return (
    <>
      {authenticateUserInfo.error?.response && (
        <FormError>
          {authenticateUserInfo.error.response.data.message}
        </FormError>
      )}
      {clientQueryInfo.isSuccess && (
        <FormRenderer
          componentMapper={componentMapper}
          FormTemplate={props => {
            return formTemplate === FormTemplateType.Seamless ? (
              <SeamlessFormTemplate
                {...props}
                submitLabel={submitLabel}
                isLoading={authenticateUserInfo.isLoading}
              >
                <div className="mt-6">
                  <div className="text-sm">
                    <Link
                      href="/forgot-password"
                      className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline focus:outline-none"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              </SeamlessFormTemplate>
            ) : (
              <FormTemplate
                {...props}
                submitLabel={submitLabel}
                isLoading={authenticateUserInfo.isLoading}
              >
                <div className="mt-5 border-t border-gray-200 pt-5">
                  <div className="text-sm">
                    <Link
                      href="/forgot-password"
                      className="font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline focus:outline-none"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                </div>
              </FormTemplate>
            );
          }}
          schema={formSchema}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
};
